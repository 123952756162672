/* eslint-disable react/no-danger */
import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import SEO from '../components/seo'
import Layout from '../components/Layout'
import MarkdownContent from '../components/MarkdownContent'

import '../components/news.css'

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { frontmatter } = data.markdownRemark
  return (
    <div>
      <SEO title={frontmatter.title} />
      <Layout>
        <div className="news__item">
          <div className="news__item-content">
            {frontmatter.image && <Img className="news__item-content--image" fluid={frontmatter.image.childImageSharp.fluid} alt={frontmatter.title} />}
            <h1>{frontmatter.title}</h1>
            <p className="news__item-content--short">{frontmatter.short_description}</p>
            <MarkdownContent content={frontmatter.description} />
            <p className="news__item-content--date">Opublikowano: {frontmatter.date}</p>
          </div>
          <div className="separator" />
        </div>
      </Layout>
    </div>
  )
}

Template.PropTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export const pageQuery = graphql`
  query NewsPostById($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        date(formatString: "DD.MM.YYYY, HH:mm")
        short_description
        description
      }
    }
  }
  `
